import React from "react";
import classNames from "classnames";
import {_t} from "../../Common/components/InjectIntlContext";

const BannerSlide = (props) => {
  return (
    <div className={classNames(props.className, "banner-slide")}>
      <div className="container">
        <div className="banner-slide__image">
          <img src={`https://income-front-data.s3.fr-par.scw.cloud/wsb/images/banner_${props.info.id}.jpg`} alt="banner_background" />
        </div>
        <div className="banner-slide__content">
          <div className="banner-slide__title">{props.info.title}</div>
          <div className="banner-slide__sub-title">{props.info.subtitle}</div>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={_t("dashboard_link_sign_up")}
            className="btn banner-slide__btn"
          >
            {_t("get_started")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerSlide;
